<template>
  <div
    class="loginCode"
  >
    You are being logged in. Please wait...
  </div>
</template>

<script>
import httpClient from '@/services/httpClient'

export default {
  mounted () {
    httpClient.post(`/auth/login`, {
      login_token: this.$route.params.code,
      otp_id: this.$route.params.otp_id
    }).then(() => {
      this.$router.replace('/')
      location.reload()
    })
  }
}
</script>

<style lang="stylus">
.loginCode {
  font-size: 18px;
  padding: 20px;
}
</style>
